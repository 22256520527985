import React from "react";
import Resume from "../components/resume/Resume";
import {PersonType} from "../model/PersonType";
import {siteMetaData} from "../siteMetaData";
import {PageProps} from "gatsby";

export default (props: PageProps) => {
  const language = "de"
  return (
    <Resume
      personType={PersonType.FirstPersonSingular}
      language={language}
      href={siteMetaData.getUrlForPath(props.location.pathname)} />
  )
}
